<template>
  <validation-observer slim>
    <v-section :title="title">
      <v-form-row class="article-edit-content__row">
        <v-form-field
          label="ЗАГОЛОВОК"
          class="article-edit-content__field--seo-title"
          vid="title"
          rules="requiredIfValuesEmpty:@linkUrl,@linkTitle,@youtubeUrl,@text"
        >
          <v-input :value="localSection.title" @input="updatePropertySection('title', $event)" />
        </v-form-field>
      </v-form-row>
      <v-form-row class="article-edit-content__row">
        <v-form-field
          label="ССЫЛКА"
          class="article-edit-content__field--seo-title"
          vid="linkUrl"
          rules="requiredIfValuesEmpty:@title,@linkTitle,@youtubeUrl,@text"
        >
          <v-input :value="localSection.linkUrl" @input="updatePropertySection('linkUrl', $event)" />
        </v-form-field>
      </v-form-row>
      <v-form-row class="article-edit-content__row">
        <v-form-field
          label="ЛЕЙБЛ ССЫЛКИ"
          class="article-edit-content__field--seo-title"
          vid="linkTitle"
          rules="requiredIfValuesEmpty:@linkUrl,@title,@youtubeUrl,@text"
        >
          <v-input :value="localSection.linkTitle" @input="updatePropertySection('linkTitle', $event)" />
        </v-form-field>
      </v-form-row>
      <v-form-row class="article-edit-content__row">
        <v-form-field
          label="ССЫЛКА НА YOUTUBE РОЛИК"
          class="article-edit-content__field--seo-title"
          vid="youtubeUrl"
          rules="requiredIfValuesEmpty:@linkUrl,@linkTitle,@title,@text"
        >
          <v-input :value="localSection.youtubeUrl" @input="updatePropertySection('youtubeUrl', $event)" />
        </v-form-field>
      </v-form-row>
      <v-form-row>
        <v-form-field
          v-slot="{ validate }"
          label="ТЕКСТ"
          separate-label
          vid="text"
          rules="requiredIfValuesEmpty:@linkUrl,@linkTitle,@youtubeUrl,@title"
        >
          <v-editor
            :value="localSection.text"
            @input="
              updatePropertySection('text', $event)
              validate($event)
            "
          />
        </v-form-field>
      </v-form-row>
      <article-edit-photos v-model="sectionPhotos" />
      <slot name="actions"></slot>
    </v-section>
  </validation-observer>
</template>

<script>
import VSection from '@/components/common/VSection.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VInput from '@/components/common/VInput.vue'
import VEditor from '@/components/common/VEditor.vue'
import ArticleEditPhotos from '@/components/Articles/Edit/Photos.vue'

export default {
  name: 'ArticlesEditDynamicSection',
  components: {
    VSection,
    VFormRow,
    VInput,
    VEditor,
    VFormField,
    ArticleEditPhotos
  },
  props: {
    section: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: null
    }
  },
  computed: {
    localSection: {
      get() {
        return this.section
      },
      set(newValue) {
        this.$emit('change', { ...this.localSection, ...newValue })
      }
    },
    sectionPhotos: {
      get() {
        const {
          newSectionMedia = {},
          deletedSectionMedia = [],
          photos = [],
          sectionMediaOrders = {}
        } = this.localSection
        return { newSectionMedia, deletedSectionMedia, photos, sectionMediaOrders }
      },
      set(photos) {
        this.localSection = { ...this.localSection, ...photos }
      }
    }
  },
  methods: {
    updatePropertySection(key, value) {
      this.localSection = { [key]: value }
    }
  }
}
</script>
