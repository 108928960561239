var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('match-media',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var mobile = ref.mobile;
return [_c('fieldset-wrapper',{attrs:{"model":_vm.model,"type":_vm.$options.SECTION_TYPE,"module":_vm.$options.MODULE_ARTICLES}},[_c('v-section',{staticClass:"article-edit-general"},[_c('v-form-row',{staticClass:"article-edit-general__row"},[_c('v-form-field',{attrs:{"label":"ОТОБРАЖЕНИЕ","separate-label":""}},[_c('div',{staticClass:"article-edit-general__checkboxes"},[_c('v-checkbox',{staticClass:"article-edit-general__checkbox",attrs:{"true-value":_vm.$options.ARTICLE_STATUS_ACTIVE,"false-value":_vm.$options.ARTICLE_STATUS_ARCHIVE},model:{value:(_vm.model.status),callback:function ($$v) {_vm.$set(_vm.model, "status", $$v)},expression:"model.status"}},[_vm._v("Опубликовано")])],1)])],1),_c('v-form-row',{staticClass:"article-edit-general__row"},[_c('v-form-field',{staticClass:"article-edit-general__field--title",attrs:{"label":"ЗАГОЛОВОК","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length},model:{value:(_vm.model.title),callback:function ($$v) {_vm.$set(_vm.model, "title", $$v)},expression:"model.title"}})]}}],null,true)})],1),_c('v-form-row',{class:['article-edit-general__row', mobile && _vm.model.previewImage && 'article-edit-general__row--image']},[_c('v-image-uploader-with-preview',{staticClass:"article-edit-general__image",attrs:{"photos":_vm.localPreviewImage,"max-photos":1},on:{"add":_vm.addPhoto,"remove":_vm.removePhoto}},[_vm._v(" Превью ")])],1),_c('v-form-row',{staticClass:"article-edit-general__row"},[_c('v-form-field',{staticClass:"article-edit-general__field article-edit-general__field--half-size",attrs:{"label":"КАТЕГОРИЯ","separate-label":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"loading":_vm.categoriesOptionsLoading,"options":_vm.categoriesOptions,"reduce":function (category) { return category.id; },"is-error":!!validationErrors.length,"label":"name"},model:{value:(_vm.model.category),callback:function ($$v) {_vm.$set(_vm.model, "category", $$v)},expression:"model.category"}})]}}],null,true)}),_c('v-form-field',{staticClass:"article-edit-general__field article-edit-general__field--half-size",attrs:{"label":"АВТОР","separate-label":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"loading":_vm.authorOptionsLoading,"options":_vm.authorOptions,"reduce":function (author) { return author.id; },"is-error":!!validationErrors.length,"label":"name"},model:{value:(_vm.model.author),callback:function ($$v) {_vm.$set(_vm.model, "author", $$v)},expression:"model.author"}})]}}],null,true)})],1),_c('v-form-row',{staticClass:"article-edit-general__row"},[_c('v-form-field',{staticClass:"article-edit-general__field--seo-title",attrs:{"label":"SEO ЗАГОЛОВОК","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length},model:{value:(_vm.model.seoTitle),callback:function ($$v) {_vm.$set(_vm.model, "seoTitle", $$v)},expression:"model.seoTitle"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"SEO ОПИСАНИЕ (ПЕРВОЕ ПРЕДЛОЖЕНИЕ)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-textarea',{attrs:{"is-error":!!validationErrors.length},model:{value:(_vm.model.seoDescription),callback:function ($$v) {_vm.$set(_vm.model, "seoDescription", $$v)},expression:"model.seoDescription"}})]}}],null,true)})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }