<template>
  <match-media v-slot="{ mobile }">
    <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_ARTICLES">
      <v-section class="article-edit-general">
        <v-form-row class="article-edit-general__row">
          <v-form-field label="ОТОБРАЖЕНИЕ" separate-label>
            <div class="article-edit-general__checkboxes">
              <v-checkbox
                v-model="model.status"
                :true-value="$options.ARTICLE_STATUS_ACTIVE"
                :false-value="$options.ARTICLE_STATUS_ARCHIVE"
                class="article-edit-general__checkbox"
                >Опубликовано</v-checkbox
              >
            </div>
          </v-form-field>
        </v-form-row>
        <v-form-row class="article-edit-general__row">
          <v-form-field
            v-slot="{ validationErrors }"
            label="ЗАГОЛОВОК"
            class="article-edit-general__field--title"
            rules="required"
          >
            <v-input v-model="model.title" :is-error="!!validationErrors.length" />
          </v-form-field>
        </v-form-row>
        <v-form-row
          :class="['article-edit-general__row', mobile && model.previewImage && 'article-edit-general__row--image']"
        >
          <v-image-uploader-with-preview
            :photos="localPreviewImage"
            :max-photos="1"
            class="article-edit-general__image"
            @add="addPhoto"
            @remove="removePhoto"
          >
            Превью
          </v-image-uploader-with-preview>
        </v-form-row>

        <v-form-row class="article-edit-general__row">
          <v-form-field
            v-slot="{ validationErrors }"
            label="КАТЕГОРИЯ"
            separate-label
            class="article-edit-general__field article-edit-general__field--half-size"
            rules="required"
          >
            <v-select
              v-model="model.category"
              :loading="categoriesOptionsLoading"
              :options="categoriesOptions"
              :reduce="category => category.id"
              :is-error="!!validationErrors.length"
              label="name"
            />
          </v-form-field>
          <v-form-field
            v-slot="{ validationErrors }"
            label="АВТОР"
            separate-label
            class="article-edit-general__field article-edit-general__field--half-size"
            rules="required"
          >
            <v-select
              v-model="model.author"
              :loading="authorOptionsLoading"
              :options="authorOptions"
              :reduce="author => author.id"
              :is-error="!!validationErrors.length"
              label="name"
            />
          </v-form-field>
        </v-form-row>

        <v-form-row class="article-edit-general__row">
          <v-form-field
            v-slot="{ validationErrors }"
            label="SEO ЗАГОЛОВОК"
            class="article-edit-general__field--seo-title"
            rules="required"
          >
            <v-input v-model="model.seoTitle" :is-error="!!validationErrors.length" />
          </v-form-field>
        </v-form-row>
        <v-form-row>
          <v-form-field v-slot="{ validationErrors }" label="SEO ОПИСАНИЕ (ПЕРВОЕ ПРЕДЛОЖЕНИЕ)" rules="required">
            <v-textarea v-model="model.seoDescription" :is-error="!!validationErrors.length" />
          </v-form-field>
        </v-form-row>
      </v-section>
    </fieldset-wrapper>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VSection from '@/components/common/VSection.vue'
import VSelect from '@/components/common/VSelect.vue'
import VFormField from '@/components/form/VFormField.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VCheckbox from '@/components/common/VCheckbox.vue'
import VInput from '@/components/common/VInput.vue'
import VImageUploaderWithPreview from '@/components/common/VImageUploaderWithPreview.vue'
import VTextarea from '@/components/common/VTextarea.vue'
import { articlesService } from '@/services/http'
import { cloneDeep, isObject, isString } from '@/utils/common'
import { mapGetters, mapState } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { GENERAL_SECTION, MODULE_ARTICLES } from '@/store/modules/article/article.types'
import { ARTICLE_STATUS_ACTIVE, ARTICLE_STATUS_ARCHIVE } from '@/constants/statuses/article'

const SECTION_TYPE = GENERAL_SECTION

export default {
  SECTION_TYPE,
  MODULE_ARTICLES,
  ARTICLE_STATUS_ACTIVE,
  ARTICLE_STATUS_ARCHIVE,
  name: 'ArticleEditGeneral',
  components: {
    MatchMedia,
    FieldsetWrapper,
    VSection,
    VFormField,
    VFormRow,
    VCheckbox,
    VInput,
    VSelect,
    VImageUploaderWithPreview,
    VTextarea
  },
  data() {
    return {
      model: {},
      authorOptionsLoading: false,
      categoriesOptionsLoading: false,
      categoriesOptions: [],
      authorOptions: []
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    ...mapState(MODULE_ARTICLES, {
      stateModel: state => state[SECTION_TYPE]
    }),
    localPreviewImage: {
      get() {
        if (isObject(this.model.previewImage)) return [this.model.previewImage]
        if (isString(this.model.previewImage))
          return [
            {
              image: this.model.previewImage,
              id: null
            }
          ]
        return []
      },
      set(newImage) {
        this.model.previewImage = newImage
      }
    }
  },
  created() {
    this.model = cloneDeep(this.stateModel)
    this.selectCategories()
    this.selectAuthors()
  },
  methods: {
    addPhoto(photo) {
      this.localPreviewImage = photo
    },
    removePhoto() {
      this.localPreviewImage = null
    },
    selectCategories() {
      this.categoriesOptionsLoading = true
      articlesService
        .selectCategory()
        .then(categories => {
          this.categoriesOptions = categories
        })
        .finally(() => {
          this.categoriesOptionsLoading = false
        })
    },
    selectAuthors() {
      this.authorOptionsLoading = true
      articlesService
        .selectAuthors()
        .then(categories => {
          this.authorOptions = categories
        })
        .finally(() => {
          this.authorOptionsLoading = false
        })
    }
  }
}
</script>
