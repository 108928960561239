<template>
  <match-media>
    <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_ARTICLES">
      <div class="articles-edit-content">
        <article-dynamic-section
          v-for="(section, index) in model.sections"
          :key="index"
          :section="section"
          :title="`СЕКЦИЯ #${index + 1}`"
          class="articles-edit-content__section"
          @change="changeSection(index, $event)"
        >
          <template #actions>
            <button
              class="articles-edit-content__button articles-edit-content__button--delete"
              @click="deleteSection(index)"
            >
              - Удалить секцию #{{ index + 1 }}
            </button>
          </template>
        </article-dynamic-section>
        <button
          v-if="isContentTabFilled || !model.sections.length"
          class="articles-edit-content__button articles-edit-content__button--add"
          @click="addSection"
        >
          <v-icon-plus class="articles-edit-content__button-icon" />
          Добавить секцию
        </button>
      </div>
    </fieldset-wrapper>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VIconPlus from '@/components/icons/VPlus.vue'
import ArticleDynamicSection from '@/components/Articles/Edit/DynamicSection.vue'
import { mapState } from 'vuex'
import { cloneDeep } from '@/utils/common'
import { CONTENT_SECTION, MODULE_ARTICLES } from '@/store/modules/article/article.types'

const SECTION_TYPE = CONTENT_SECTION

function generateSectionField() {
  return {
    title: null,
    linkUrl: null,
    linkTitle: null,
    youtubeUrl: null,
    text: null,
    photos: [],
    newSectionMedia: {},
    deletedSectionMedia: [],
    sectionMediaOrders: {}
  }
}

export default {
  SECTION_TYPE,
  MODULE_ARTICLES,
  name: 'ArticleEditContent',
  components: {
    MatchMedia,
    FieldsetWrapper,
    ArticleDynamicSection,
    VIconPlus
  },
  props: {
    isContentTabFilled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: {}
    }
  },
  computed: {
    ...mapState(MODULE_ARTICLES, {
      id: state => state.id,
      stateModel: state => state[SECTION_TYPE]
    })
  },
  created() {
    this.model = cloneDeep(this.stateModel)
    if (this.model.sections.length === 0) {
      this.addSection()
    }
  },
  methods: {
    addSection() {
      this.model.sections.push(generateSectionField())
    },
    deleteSection(index) {
      this.model.sections.splice(index, 1)
    },
    changeSection(index, section) {
      this.model.sections.splice(index, 1, section)
    }
  }
}
</script>
