<template>
  <match-media v-slot="{ mobile }">
    <v-section class="article-edit-photos" :title="mobile ? 'ФОТОГРАФИИ' : ''">
      <v-image-uploader-with-preview :photos="photos" @add="addImage" @change="changePhotos" @remove="removeImage" />
    </v-section>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VImageUploaderWithPreview from '@/components/common/VImageUploaderWithPreview.vue'
import VSection from '@/components/common/VSection.vue'
import { cloneDeep } from '@/utils/common'

export default {
  name: 'ArticleEditPhotos',
  components: { MatchMedia, VSection, VImageUploaderWithPreview },
  model: {
    prop: 'sectionMedia',
    event: 'change'
  },
  props: {
    sectionMedia: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      model: {
        newSectionMedia: {},
        deletedSectionMedia: [],
        sectionMediaOrders: {}
      },
      photos: []
    }
  },
  watch: {
    sectionMedia: {
      immediate: true,
      handler({ newSectionMedia, deletedSectionMedia, sectionMediaOrders }) {
        this.model = { newSectionMedia, deletedSectionMedia, sectionMediaOrders }
      }
    }
  },
  created() {
    if (this.sectionMedia.photos) {
      this.photos = cloneDeep(this.sectionMedia.photos).sort((a, b) => a.myOrder - b.myOrder)
    }
  },
  methods: {
    changePhotos(photos) {
      this.photos = photos
      this.changePhotoOrders()
    },
    addImage(photo) {
      this.photos.push(photo)
      this.model.newSectionMedia[photo.id] = this.photos.length - 1
      this.$emit('change', { ...this.model })
    },
    removeImage(photo) {
      if (this.isCustomPhoto(photo)) delete this.model.newSectionMedia[photo.id]
      else if (this.isArticlePhoto(photo)) this.model.deletedSectionMedia.push(photo.id)

      this.photos = this.photos.filter(p => p.id !== photo.id)

      this.$emit('change', { ...this.model })
    },
    changePhotoOrders() {
      const { sectionMediaOrders, newSectionMedia } = this.photos.reduce(
        (acc, { id }, index) => {
          if (this.isCustomPhoto({ id })) acc.newSectionMedia[id] = index
          else if (this.isArticlePhoto({ id })) acc.sectionMediaOrders[id] = index

          return acc
        },
        { sectionMediaOrders: {}, newSectionMedia: {} }
      )
      this.model = { ...this.model, sectionMediaOrders, newSectionMedia }

      this.$emit('change', { ...this.model })
    },
    isCustomPhoto({ id }) {
      return this.model.newSectionMedia && `${id}` in this.model.newSectionMedia
    },
    isArticlePhoto({ id }) {
      return this.sectionMedia.photos && this.sectionMedia.photos.find(photo => photo.id === id)
    }
  }
}
</script>
