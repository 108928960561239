<template>
  <vue-editor v-bind="$attrs" :editor-toolbar="editorToolbar" v-on="$listeners" />
</template>

<script>
import { VueEditor } from 'vue2-editor'

const DEFAULT_EDITOR_TOOLBAR = [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']]

export default {
  name: 'VEditor',
  components: {
    VueEditor
  },
  props: {
    editorToolbar: {
      type: Array,
      default: () => DEFAULT_EDITOR_TOOLBAR
    }
  }
}
</script>
