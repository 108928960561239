<template>
  <match-media v-slot="{ mobile }">
    <validation-observer ref="commonObserver" tag="div" class="edit-article">
      <div class="edit-article__header">
        <v-breadcrumbs v-if="!mobile" :items="breadcrumbItems" class="edit-article__breadcrumbs" />

        <v-button-go-back v-else @click="goBack" />

        <v-page-title class="edit-article__title">{{ title }}</v-page-title>
        <div v-if="mobile" class="edit-article__title-tab">{{ $options.ACTIVE_SECTION_ITEM[activeSection] }}</div>

        <div v-if="!mobile" class="edit-article__tabs-wrapper">
          <v-tabs v-model="activeSection" :primary="mobile">
            <v-tab :name="$options.GENERAL">
              <span v-if="validations.generalSectionValid" class="edit-article__tab-icon"><v-icon-tick /> </span>
              Общее
            </v-tab>
            <v-tab :name="$options.CONTENT">
              <span v-if="validations.contentSectionValid" class="edit-article__tab-icon"><v-icon-tick /> </span>
              Содержание
            </v-tab>
          </v-tabs>
        </div>
      </div>

      <div class="edit-article__content">
        <div v-if="mobile && !activeSection" class="edit-article__mobile-menu">
          <v-menu v-model="activeSection">
            <v-menu-item :name="$options.GENERAL">
              <span class="edit-article__menu-item">
                Общее
                <v-icon-tick v-if="validations.generalSectionValid" />
              </span>
            </v-menu-item>
            <v-menu-item :name="$options.CONTENT">
              <span class="edit-article__menu-item">
                Содержание
                <v-icon-tick v-if="validations.contentSectionValid" />
              </span>
            </v-menu-item>
          </v-menu>
        </div>

        <!-- sections -->
        <div v-if="!loading" class="edit-article__sections-wrapper">
          <article-edit-general v-show="activeSection === $options.GENERAL" />

          <article-edit-content
            v-show="activeSection === $options.CONTENT"
            :is-content-tab-filled="validations.contentSectionValid"
          />

          <div v-if="!mobile || !activeSection" class="edit-article__actions">
            <template v-if="isCreation || isDraft">
              <v-button class="edit-article__action" :disabled="loading" @click="saveAsDraft"
                >Сохранить черновик</v-button
              >
              <v-button primary class="edit-article__action" :disabled="!isValidForSubmit || loading" @click="save"
                >Опубликовать</v-button
              >
            </template>
            <v-button
              v-else
              primary
              class="edit-article__action"
              :disabled="!isValidForSubmit || loading"
              @click="updateArticle(articleModel)"
              >Обновить</v-button
            >
          </div>
        </div>
      </div>
    </validation-observer>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VBreadcrumbs from '@/components/common/VBreadcrumbs.vue'
import VPageTitle from '@/components/common/VPageTitle.vue'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import VMenu from '@/components/common/VMenu.vue'
import VMenuItem from '@/components/common/VMenuItem.vue'
import VIconTick from '@/components/icons/VTick.vue'
import VButtonGoBack from '@/components/common/VButtonGoBack.vue'
import VButton from '@/components/common/VButton.vue'
import ArticleEditGeneral from '@/components/Articles/Edit/General.vue'
import ArticleEditContent from '@/components/Articles/Edit/Content.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  FETCH_ARTICLE_BY_ID,
  MODULE_ARTICLES,
  GENERAL_SECTION,
  CONTENT_SECTION,
  RESET_STATE,
  CREATE_ARTICLE,
  UPDATE_ARTICLE,
  FULL_ARTICLE_MODEL
} from '@/store/modules/article/article.types'
import { ARTICLE_STATUS_ARCHIVE, ARTICLE_STATUS_ACTIVE, ARTICLE_STATUS_DRAFT } from '@/constants/statuses/article'
import { ACTIVE_SECTION_ITEM, GENERAL, CONTENT } from '@/constants/articles'
import { loadingService } from '@/services/loading'
import noticeService from '@/services/notification'

export default {
  ACTIVE_SECTION_ITEM,
  GENERAL,
  CONTENT,
  name: 'ArticleEdit',
  components: {
    MatchMedia,
    VBreadcrumbs,
    VPageTitle,
    VTabs,
    VTab,
    VMenu,
    VMenuItem,
    VButtonGoBack,
    VIconTick,
    ArticleEditGeneral,
    ArticleEditContent,
    VButton
  },
  inject: ['mediaQueries'],
  data() {
    return {
      validations: {
        generalSectionValid: false,
        contentSectionValid: false
      }
    }
  },
  computed: {
    isValidForSubmit() {
      return (
        this.validations.generalSectionValid &&
        (this.articleModel.sections.length === 1 ||
          (this.validations.contentSectionValid && this.articleModel.sections.length > 1))
      )
    },
    activeSection: {
      get() {
        return this.$route.query.tab || null
      },
      set(val) {
        this.$router.push({ query: { tab: val } })
      }
    },
    articleId() {
      return this.$route.params.articleId
    },
    isCreation() {
      return !this.articleId
    },
    isDraft() {
      return this.articleModel.status === ARTICLE_STATUS_DRAFT
    },
    title() {
      return this.isCreation ? 'Новая статья' : 'Редактирование статьи'
    },
    breadcrumbItems() {
      return [
        {
          text: 'Блог',
          to: { name: 'articles' }
        },
        {
          text: this.isCreation ? 'Новая статья' : 'Редактирование статьи',
          disabled: true
        }
      ]
    },
    ...mapGetters(MODULE_ARTICLES, {
      articleModel: FULL_ARTICLE_MODEL
    }),
    ...mapState(MODULE_ARTICLES, {
      loading: state => state.loading
    })
  },
  watch: {
    loading(val) {
      loadingService.setViewLoading(val)
    }
  },
  created() {
    if (!this.activeSection && !this.mediaQueries.mobile) {
      this.$router.replace({ query: { tab: GENERAL } })
    }

    if (!this.isCreation) {
      this.fetchArticleById(this.articleId)
    }
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.commonObserver.observers.find(obs => obs.vid === GENERAL_SECTION)?.flags.valid
      },
      isValid => {
        this.validations.generalSectionValid = isValid
      }
    )
    this.$watch(
      () => {
        return (
          this.$refs.commonObserver.observers.find(obs => obs.vid === CONTENT_SECTION)?.flags.valid &&
          !!this.articleModel.sections.length
        )
      },
      isValid => {
        this.validations.contentSectionValid = isValid
      }
    )
  },
  beforeDestroy() {
    this.resetState()
  },
  methods: {
    ...mapActions(MODULE_ARTICLES, {
      fetchArticleById: FETCH_ARTICLE_BY_ID,
      resetState: RESET_STATE,
      createArticle: CREATE_ARTICLE,
      updateArticle: UPDATE_ARTICLE
    }),
    save() {
      if (this.isCreation) {
        this.createArticle({ ...this.articleModel, status: ARTICLE_STATUS_ARCHIVE })
          .then(() => {
            noticeService.success('Статья опубликована!').then(() => {
              this.$router.push({ name: 'articles' })
            })
          })
          .catch(() => noticeService.error('Не удалось опубликовать статью!'))
      } else {
        this.updateArticle({ ...this.articleModel, status: ARTICLE_STATUS_ACTIVE })
          .then(() => {
            noticeService.success('Статья обновлена!').then(() => {
              this.$router.push({ name: 'articles' })
            })
          })
          .catch(() => noticeService.error('Не удалось обновить статью!'))
      }
    },

    saveAsDraft() {
      if (this.isCreation) {
        this.createArticle({ ...this.articleModel, status: ARTICLE_STATUS_DRAFT })
          .then(() => {
            noticeService.success('Статья сохранена в черновики!').then(() => {
              this.$router.push({ name: 'articles' })
            })
          })
          .catch(() => noticeService.error('Не удалось сохранить черновик статьи!'))
      } else {
        this.updateArticle(this.articleModel)
          .then(() => {
            noticeService.success('Черновик статьи обновлен!').then(() => {
              this.$router.push({ name: 'articles' })
            })
          })
          .catch(() => noticeService.error('Не удалось обновить черновик статьи!'))
      }
    },
    goBack() {
      if (!this.activeSection) {
        this.$router.push({ name: 'articles' })
      } else if (this.activeSection) {
        this.activeSection = null
      }
    }
  }
}
</script>
